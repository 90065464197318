import React from 'react'
import Layout from '../components/layout'
import Header from '../components/Header'
import { Helmet } from 'react-helmet'
import ISO from '../images/ISOTransparent.png'
import REACH from '../images/Reach.png'
import Success from '../images/success.png'
import Mission from '../images/mission.png'
import Partners from '../components/Partners/Partners'

const AboutUs = () => (
  <Layout>
    <Helmet>
      <title>Get to know IN-ECO</title>
    </Helmet>
    <Header styleName="header" title="About us" />
    <div className="content">
      <div className="columns content-row">
        <div className="column content-text">
          <div className="title-small content-title">
            Experience and Production
          </div>
          JSC “Inžinerinė Ekologija” is a company specializing in chemical
          engineering founded by the technologists with more than 25 years of
          experience.
          <br />
          <br />
          As a team we have a record of implementing chemical treatment programs
          and provide engineering support in the field of process water
          treatment and in sugar, paper and oil production industries.
          <br />
          <br />
          Our specialized chemical treatment programs are enabled through the
          chemical products we develop and license under IN-ECO<sup>®</sup>{' '}
          trademark.
          <br />
          <br />
          The quality of IN-ECO<sup>®</sup> products is of utmost importance to
          us therefore to follow the standards of ISO 9001 and ISO 14001
          certification we carry out a thorough quality checks during the entire
          production process, from raw materials to finished IN-ECO<sup>®</sup>{' '}
          goods. In addition, our business follows REACH regulations and ensures
          health and safety in the work environment.
        </div>
        <div className="column content-img">
          <img src={ISO} className="iso-img" style={{ width: '50%' }} alt="" />
          <img src={REACH} className="iso-img" alt="" />
        </div>
      </div>
      <div className="columns content-row">
        <div className="column content-img">
          <img src={Mission} alt="" />
        </div>
        <div className="column content-text">
          <div className="title-small content-title">Mission</div>
          We strive to succeed as a reliable and consistent manufacturer as well
          as an excellent supplier. This means setting ourselves with a goal of
          distributing only high-grade chemicals that meet high consumer
          requirements.
          <br />
          <br />
          Together with IN-ECO<sup>®</sup> chemicals we seek to provide
          engineering solutions to achieve a trouble-free operation of the
          equipment. As a guarantee of the long-lasting effectiveness of our
          products we aim to provide regular after sales support and technical
          consulting.
        </div>
      </div>
      <div className="columns content-row">
        <div className="column content-text">
          <div className="title-small content-title">
            Our principles of success
          </div>
          Usually the collaboration with client starts with the team of
          experienced engineers diligently assessing every customer’s case by
          performing a deep analysis of their systems and technological
          processes.
          <br />
          <br />
          After selecting the optimal solution we will supply the customer with
          the right IN-ECO<sup>®</sup> products.
          <br />
          <br />
          Finally, a regular communication and a technical support ensure a
          constant monitoring and timely adjustments to continue the flawless
          operation of the industrial process.
        </div>
        <div className="column content-img">
          <img src={Success} alt="" />
        </div>
      </div>
      <div className="title-small content-title"> Our partners</div>
      <Partners />
    </div>
  </Layout>
)

export default AboutUs
